import React from 'react';

const ServiceInsideDescription = () => {
    return (
        <div id="description" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="description">
                    <p>Lorem Ipsservices um is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                </div>
            </div>
        </div>
    );
};

export default ServiceInsideDescription;