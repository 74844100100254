import React from 'react';

const ContactsInside = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p><a href="mailto:info@integrativemedicineofmidtown.com">info@integrativemedicineofmidtown.com</a></p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Email</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>274 Madison Ave, New York, 10016, USA</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Address</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p><a href="tel:005053255350539">1 (646) 396-3024</a></p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Phone</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactsInside;
