import React from 'react';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img" style={ { backgroundImage: 'url(assets/img/placeholder/showcase.png)' } }>
            <div className="wrapper text-center d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">Integrative Medicine of Midtown </h1>
                    </div>

                    <div className="description spacer p-top-lg">
                        <p>Double Board Certified Physician in Family Medicine and Obstetrics, Specializing in Integrative Women's Health & Gynecology, located in Yorktown Heights, NY & Manhattan, NY</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <a href={ process.env.PUBLIC_URL + "/about-us" } className="btn btn-primary">Learn More</a>
                    </div>

                    <nav className="languages lang-position">
                        <ul className="nav">
                            <li className="lang-item current-lang">
                                <a title="En" href={ process.env.PUBLIC_URL + "/" }>En</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
