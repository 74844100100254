import React from 'react';

const NewsTitle = () => {
    return (
        <div className="title">
            <h2>Can Botox be used for Migraines?</h2>
        </div>
    );
};

export default NewsTitle;
