import React, { useState, useEffect } from 'react';


const AboutsUs = () => {
    const [ pageAbout, setPage ] = useState( false );

    useEffect(() => {
        window.location.pathname.includes("about-us") ? setPage( true ) : setPage( false );
    }, []);

    return (
        <div id="about-us" className={ "block  before-block spacer p-top-xl " + ( pageAbout ? "bg-gray-light p-bottom-xl" : " " ) }>
            <div className="wrapper">
                <div className="row gutter-width-sm with-pb-md">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="description-2">
                            <h2>Double Board Certified Physician in Family Medicine and Obstetrics located in Yorktown Heights, NY & Manhattan, NY</h2>
                        </div>

                        <div className="signature spacer m-top-lg">
                            <div className="signature-img">
                                <img src="assets/img/demo/33_img.png" alt="Signature" />
                            </div>

                            <div className="signature-content">
                                <h5 className="signature-name">Dr Monica Grover</h5>
                                <p className="signature-position">D.O.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="description-2">
                            <p> Dr. Monica Grover is the medical director at Asira Medical and Beauty Scripts in Yorktown Heights, New York. She is double board certified in Family Medicine and Obstetrics and has extensive experience working with a diverse range of patients. Her goal is to use an integrative approach to help patients achieve a healthy body and mind.Dr. Grover completed her bachelor's degree in psychology and biology at SUNY Stony Brook. She also holds a Master of Science degree in Clinical Nutrition from New York Institute of Technology.</p>
                        </div>

                        <div className="description-2 spacer p-top-lg">
                            <p> After completing her undergraduate education, Dr. Grover went on to earn her medical degree at New York College of Osteopathic Medicine and performed her residency in Family Medicine at Long Beach Medical Center. She later completed a fellowship in Women's Health at SUNY Downstate Medical Center. In her practice, Dr. Grover emphasizes a holistic approach to health and wellness. She believes that a healthy lifestyle is a crucial part of maintaining a healthy body. Dr. Grover knows that each patient is unique. She ensures that each patient receives the personalized care they need to achieve optimum health.</p>
                        </div>
                    </div>
                </div>

                <div className="icon-list">
                    <div className="icon-list-items">
                        <div className="icon-list-item">
                            <p><span className="d-flex"><i className="fas fa-check"></i><span>Constant Improvement</span></span></p>
                        </div>

                        <div className="icon-list-item">
                            <p><span className="d-flex"><i className="fas fa-check"></i><span>Commitment to Customers</span></span></p>
                        </div>

                        <div className="icon-list-item">
                            <p><span className="d-flex"><i className="fas fa-check"></i><span>Best Quality You Can Get</span></span></p>
                        </div>

                        <div className="icon-list-item">
                            <p><span className="d-flex"><i className="fas fa-check"></i><span>More than 30 available services</span></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutsUs;

